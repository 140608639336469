<template>
  <div class="about">
    <div class="navBar">
      <div @click="$router.go(-1)" class="back">
        <svg-icon class="leftBtn" iconClass="back"></svg-icon>
        <span>返回</span>
      </div>
      <div class="navTitle">关于APP</div>
      <div class="rightBtn"></div>
    </div>
    <img class="logo" :src="logoUrl" />
    <div class="appName">软妹社</div>
    <div class="version">当前版本：v1.0.0</div>
    <div class="statement">©软妹社 版权所有</div>
    <div class="title">下载地址</div>
    <div class="content" @click="copyUrl(downloadUrl)">{{ downloadUrl }}</div>
    <div class="title">官方邮箱</div>
    <div class="content" @click="copyUrl(eMail)">{{ eMail }}</div>
    <div class="title">商务合作</div>
    <div class="content" @click="copyUrl(cooperation)">{{ cooperation }}</div>
  </div>
</template>

<script>
import { Toast } from 'vant';
export default {
  name: 'about',
  data() {
    return {
      version: '',
      downloadUrl: '',
      eMail: '',
      cooperation: '',
      logoUrl: require('@/assets/png/appLogo.png'),
    };
  },
  created() {
    this.getUrl();
  },
  methods: {
    getUrl() {
      let appConfig = this.$store.getters.appConfig;
      appConfig.sourceList.forEach((i) => {
        if (i.type == 'GROUPEMAIl') {
          this.eMail = i.domain[0].url;
        } else if (i.type == 'BusinessCooperation') {
          this.cooperation = i.domain[0].url;
        } else if (i.type == 'LAND') {
          this.downloadUrl = i.domain[0].url;
        }
      });
    },
    copyUrl(url) {
      this.$copyText(url).then(
        (e) => {
          Toast('复制成功');
        },
        (e) => {
          Toast('复制失败');
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.about {
  font-size: 13px;
  text-align: center;
  overflow: hidden;

  .navBar {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    background-color: #fff;
    box-sizing: border-box;
    border-bottom: 1px solid rgb(217, 217, 217);
    .back {
      width: 80px;
      display: flex;
      align-items: center;

      .leftBtn {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }

      span {
        font-size: 18px;
      }
    }

    .navTitle {
      flex: 1;
      font-size: 18px;
      text-align: center;
      color: #000;
    }

    .rightBtn {
      width: 80px;
    }
  }

  .logo {
    width: 90px;
    height: 90px;
    display: block;
    margin: 63px auto 15px;
  }

  .appName {
    text-align: center;
    font-size: 21px;
  }

  .version {
    // font-size: 13px;
    font-size: 16px;
    margin-top: 5px;
    // text-align: center;
    color: rgb(51, 51, 51);
  }

  .statement {
    color: rgb(51, 51, 51);
    font-size: 16px;
    // font-size: 13px;
    margin-top: 9px;
    // text-align: center;
  }

  .title {
    font-size: 18px;
    margin: 40px 0 4px;
    color: rgb(51, 51, 51);
  }

  .content {
    font-size: 16px;
    color: rgb(51, 51, 51);
  }
}
</style>
